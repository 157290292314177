import React from 'react';
import { darken, withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Box, Input, Grid, FormControl, Link, useMediaQuery } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ManagerApi from 'src/services/managerApi';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from 'src/actions';
import dialogIllustration from 'src/theme/scss/images/modal-telefone.png';
import closeModalIcon from 'src/theme/scss/icons/close-modal.svg'
import { useHistory } from 'react-router';
import { useTheme } from '@material-ui/styles';

const DialogContent = withStyles((theme) => ({
    root: {
        padding: '80px 60px',
        backgroundColor: '#F4F4F4',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            padding: '48px 24px',
        }
    },
}))(MuiDialogContent);

const DialogCloseButton = withStyles((theme) => ({
    root: {
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'translate(50%, -50%)',
        filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1))'
    },
}))(Box);

const TypographyTitle = withStyles((theme) => ({
    root: {
        color: '#000000',
        fontSize: '30px',
        lineHeight: '1.2',
        fontFamily: 'Gilroy-ExtraBold',
        textAlign: 'center',
        '& span': {
            display: 'block',
            fontSize: '16px',
            color: '#ff367d',
            marginBottom: '40px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
    },
}))(Typography);

const TypographySubtitle = withStyles((theme) => ({
    root: {
        color: '#000000',
        fontSize: '24px',
        lineHeight: '1.2',
        fontFamily: 'Gilroy-ExtraBold',
        marginBottom: '8px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
}))(Typography);

const TypographyText = withStyles((theme) => ({
    root: {
        color: '#000000',
        fontSize: '16px',
        lineHeight: '1.2',
        fontFamily: 'Gilroy-Light',
        marginBottom: '40px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
        },
    },
}))(Typography);

const SubmitButton = withStyles((theme) => ({
    root: {
        color: '#FFFFFF',
        backgroundColor: '#ff367d',
        display: 'block',
        textAlign: 'center',
        '&:hover': {
            color: '#FFFFFF',
            textDecoration: 'none',
            backgroundColor: darken('#ff367d', 0.1),
        }
    }
}))(Button);

const TypographyFooter = withStyles((theme) => ({
    root: {
        color: '#000000',
        fontSize: '14px',
        lineHeight: '1.2',
        fontFamily: 'Gilroy-Light',
        textAlign: 'center',
        marginTop: '8px',
        [theme.breakpoints.down('sm')]: {
        },
    },
}))(Typography);

export default function DialogTelefoneVendas({ handleClickOpen, open, handleClose }) {
    const store = useSelector((state) => state.interface.store);

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    let linkWhatsapp = false;
    if (store.contacts.whatsapp) {
        if (mobileSize) {
            linkWhatsapp = 'https://wa.me/' + store.contacts.whatsapp.match(/\d+/g).join([]);
        }
        else {
            linkWhatsapp = 'https://api.whatsapp.com/send?phone=' + store.contacts.whatsapp.match(/\d+/g).join([]);
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                PaperProps={{ style: { width: '600px', maxWidth: '100%', overflow: 'visible' } }}
            >
                <DialogCloseButton onClick={handleClose}>
                    <img src={closeModalIcon} alt="" />
                </DialogCloseButton>
                <DialogContent>

                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <TypographyTitle>
                                Como podemos te ajudar?
                                <span>Fale agora com nossos especialistas</span>
                            </TypographyTitle>

                            <TypographySubtitle>
                                Central de Suporte
                            </TypographySubtitle>

                            <TypographyText>
                                {store.contacts.whatsapp.replace("+55 ", "")}
                            </TypographyText>

                            <TypographySubtitle>
                                Horário de Atendimento
                            </TypographySubtitle>

                            <TypographyText>
                                Segunda a sexta das 08h às 17h45
                            </TypographyText>

                            <SubmitButton
                                component={Link}
                                target='_blank'
                                href={linkWhatsapp}
                                variant="contained"
                            >Iniciar conversa agora</SubmitButton>

                            <TypographyFooter>
                                Contato exclusivo para mensagens
                            </TypographyFooter>

                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </div>
    );
}