import React from "react";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import HTMLView from 'src/components/HTMLView';
import { getBoxMarginProps, getBreakpointValue, getColor } from "./functions";

export const Text = (props) => {
	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

	const textStyle = {};

	const color = getColor(props.color);
	const fontSize = getBreakpointValue(props, breakpoint, 'size');
	const lineHeight = getBreakpointValue(props, breakpoint, 'lineHeight');

	if (color) textStyle.color = color;
	if (fontSize) textStyle.fontSize = `${fontSize}px`;
	if (lineHeight) textStyle.lineHeight = lineHeight;

	return (
		<Box
			{...getBoxMarginProps(props, breakpoint)}
			sx={{
				textAlign: getBreakpointValue(props, breakpoint, 'textAlign'),
				'h1, h2, h3, h4, h5, h6': {
					fontWeight: 'normal',
				},
			}}
		>
			<HTMLView html={props.text} />
		</Box>
	);
}