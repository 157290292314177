import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { getProductValues } from 'src/utils/functions';
import FavoriteButton from '../common/FavoriteButton';

const useStyles = makeStyles({
    link: {
        display: 'block',
        textDecoration: 'none',
        height: '100%',
    },
    paper: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        borderRadius: '16px 12px 12px 16px',
        backgroundColor: '#232327',
        color: '#E1E1E1',
        position: 'relative',
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        '& img': {
            width: '100%',
            height: 'auto',
            display: 'block',
        },
    },
    details: {
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    productName: {
        fontSize: '14px',
        fontWeight: '700px',
    },
    attributes: {
        marginTop: '4px',
        marginBottom: '8px',
        fontSize: '10px',
        color: '#C4C4C4',
    },
    attributeSeparator: {
        display: 'inline-block',
        height: '12px',
        width: '2px',
        backgroundColor: '#565656',
        marginInline: '4px',
        verticalAlign: 'middle',
    },
    pricePrefix: {
        color: 'inherit',
        fontSize: '8px',
        textTransform: 'uppercase',
        lineHeight: '1',
    },
    priceValue: {
        fontFamily: 'Gilroy-ExtraBold',
        color: 'inherit',
        display: 'inline-block',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '1',
        marginRight: '4px',
    },
    priceSufix: {
        color: 'inherit',
        fontSize: '8px',
        display: 'inline-block',
        lineHeight: '1',
        marginRight: '4px',
    },
    discountBadge: {
        fontSize: '8px',
        display: 'inline-block',
        color: '#000000',
        backgroundColor: '#39D950',
        textTransform: 'uppercase',
        padding: '1px 4px',
        borderRadius: '4px',
    },
    favoriteButton: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '4px',
        '& svg': {
            color: '#C4C4C4',
            width: '16px',
            height: '16px',
            '&.filled': {
                color: '#FF367D',
            },
        },
    },
});

function MobileProductCard({ product }) {
    const classes = useStyles();

    const imageSrc = product.medias[0]?.url;

    const discountPercentage = 10;
    const { formattedDiscountPrice } = getProductValues(product, discountPercentage);

    const attributes = useMemo(() => {
        if (!product.attributes) {
            return;
        }
        const attributes = product.attributes
            .filter(attribute => attribute.isList)
            .map(attribute => ({ ...attribute, type: attribute.type }))
            .sort((a, b) => a.sortOrder - b.sortOrder);
        return attributes
            .filter(attribute => attribute.type === 'text' ? attribute.optionId : attribute.optionLabel)
            .map(attribute => (
                <>
                    {attribute.type === 'text' ? attribute.optionId : attribute.optionLabel}
                    <br />
                </>
            ))
    }, [product]);

    return (
        <Link to={`/${product.url}`} className={classes.link}>
            <Paper elevation={0} className={classes.paper}>
                <Box className={classes.image}>
                    <img src={imageSrc} alt={product.name} />
                </Box>
                <Box className={classes.details}>
                    <Typography variant="h3" className={classes.productName}>
                        {product.name}
                    </Typography>
                    {attributes && <Typography className={classes.attributes}>{attributes}</Typography>}
                    <Box>
                        <Typography className={classes.pricePrefix}>A partir de</Typography>
                        <Typography className={classes.priceValue}>{formattedDiscountPrice}</Typography>
                        <Typography className={classes.priceSufix}>no PIX</Typography>
                        <Typography className={classes.discountBadge}>10% de desconto</Typography>
                    </Box>
                </Box>
                <Box className={classes.favoriteButton}>
                    <FavoriteButton product={product} />
                </Box>
            </Paper>
        </Link>
    );
}

export default MobileProductCard;
