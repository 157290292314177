import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, makeStyles, darken } from '@material-ui/core';
import { addProductCart } from 'src/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Manrope',
    fontSize: '8px',
    color: '#ffffff',
    padding: '8px',
    width: '100%',
    backgroundColor: '#0077df',
    '&:hover': {
      backgroundColor: darken('#0077df', 0.1)
    },
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  loading: {
    color: '#FFFFFF',
    marginRight: '4px'
  }
}));

function AddToCartButton({ product }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddCart = async product => {
    if (isLoading) return;

    setIsLoading(true);

    if (product.type === 'simple') {
      const response = await dispatch(addProductCart({
        data: product,
        quantity: 1
      }));
      if (response) {
        return history.push('/onestepcheckout/carrinho');
      }
    } else {
      return history.push(product.url);
    }

    setIsLoading(false);
  };

  return (
    <Button
      className={classes.root}
      size="small"
      variant="contained"
      disabled={!product.saleable}
      onClick={() => handleAddCart(product)}
    >
      {product.saleable ? 'Adicionar no carrinho' : 'Indisponível'}
    </Button>
  );
}

export default AddToCartButton;
