import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addLoadingGlobal, addMessage, removeLoadingGlobal } from 'src/actions';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { useMask } from 'src/hooks/useMask';
import { v4 as uuidv4 } from 'uuid';

import {
    makeStyles,
    Button,
    Grid,
    Menu,
    Box,
    Input,
    FormControl,
    FormLabel,
    FormHelperText
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ManagerApi from 'src/services/managerApi';

import arrowLogout from 'src/theme/scss/icons/arrow-logout.svg';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    formGrid: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    formControl: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center'
        }
    },
    controlLabel: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px !important',
            lineHeight: '16px !important',
            fontWeight: '400 !important',
            width: '30%',
            maxWidth: 85,
            paddingRight: 15,
            textAlign: 'right',
            height: 'auto !important'
        }
    },
    boxInput: {
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            display: 'flex',
            width: '70%'
        }
    },
    buttonForm: {
        fontSize: '12px',
        color: '#fff',
        fontWeight: 800,
        border: '2px solid #ffffff',
        marginTop: '24px',
        padding: theme.spacing(1.7),
        borderRadius: '6px',
        '& .MuiButton-label': {
            color: '#FFF'
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#db1842',
            border: 0,
            marginTop: 0,
            width: '100%',
            '&:hover': {
                backgroundColor: '#db1842',
            }
        },
    },
    buttonFormMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            minWidth: 50,
            width: 50,
            height: 50,
            marginLeft: 10
        }
    },
    hiddenMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    iconWhite: {
        filter: `invert(100%) sepia(4%) saturate(8%) hue-rotate(96deg) brightness(104%) contrast(100%)`
    },
}));

function OrderStatus() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [translate, i18n] = useTranslation();

    const [redirectToOrderView, setRedirectToOrderView] = useState(false);

    const [order, setOrder] = useState(false);

    const { dinamicMaskDocument } = useMask();

    const validationSchema = Yup.object().shape({
        id: Yup.string().required("Informe o número do pedido"),
        customerDocument: Yup.string().min(14, translate('errorMinimunString')).max(20).required(translate('errorRequiredField')),
    });

    let initialForm = {
        id: '',
        customerDocument: ''
    }

    if (redirectToOrderView === true && order) {
        console.log(order);
        return <Redirect to={{ pathname: '/guest/order/', state: { order: JSON.stringify(order) } }} />
    }

    return (
        <Box
            id="status-menu"
            className="statusMenu"
        >
            <Formik
                validationSchema={validationSchema}
                initialValues={initialForm}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    const requestId = uuidv4();

                    try {
                        dispatch(addLoadingGlobal(requestId));

                        const orderApi = new ManagerApi('/sale/order/guest/view');

                        const response = await orderApi.post(values);

                        if (response.data) {
                            if (response.data.success && response.data.content) {
                                setOrder(response.data.content);
                                setRedirectToOrderView(true);
                                dispatch(removeLoadingGlobal(requestId));
                                return true;
                            }
                        }
                        dispatch(addMessage('Pedido não encontrado!', 'error'));
                    } catch (error) {
                        dispatch(addMessage('Pedido não encontrado!', 'error'));
                    }
                    dispatch(removeLoadingGlobal(requestId));
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    touched,
                    errors
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={1} className={classes.formGrid}>
                                <Grid item>
                                    <FormControl className={classes.formControl}>
                                        <FormLabel className={classes.controlLabel}>Número do pedido:</FormLabel>
                                        <Box className={classes.boxInput}>
                                            <Input
                                                label="Número do pedido"
                                                name="id"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.id}
                                                placeholder="Número do pedido"
                                                className="controlInputStatus" />
                                        </Box>
                                    </FormControl>
                                    <FormHelperText error>
                                        {errors.id}
                                    </FormHelperText>
                                </Grid>
                                <Grid item>
                                    <FormControl className={classes.formControl}>
                                        <FormLabel className={classes.controlLabel}>Qual seu CPF/CNPJ?</FormLabel>
                                        <Box className={classes.boxInput}>
                                            <InputMask
                                                mask={dinamicMaskDocument(values.customerDocument)}
                                                maskChar={null}
                                                value={values.customerDocument}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            >
                                                {(inputProps) => (
                                                    <Input
                                                        label="CPF"
                                                        name="customerDocument"
                                                        placeholder="Meu CPF/CNPJ é:"
                                                        className="controlInputStatus"
                                                        error={Boolean(touched.customerDocument && errors.customerDocument)}
                                                        helperText={touched.customerDocument && errors.customerDocument}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputMask>
                                            <Button type="submit" className={classes.buttonFormMobile + ' ' + classes.buttonForm}>
                                                <img src={arrowLogout} className={classes.iconWhite} />
                                            </Button>
                                        </Box>
                                    </FormControl>
                                    <FormHelperText error>
                                        {errors.customerDocument}
                                    </FormHelperText>
                                </Grid>
                                <Grid item className={classes.hiddenMobile}>
                                    <Button type="submit" className={classes.buttonForm}>CONSULTAR</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }}
            </Formik>

        </Box>
    );
}

OrderStatus.propTypes = {
    className: PropTypes.string
};

export default OrderStatus;
