import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    Box,
    useTheme
} from '@material-ui/core';

function HTMLView({ html, color }) {
    const theme = useTheme();
    return (
        <Box sx={{
            color: color,
            '& h1': theme.typography.h1,
            '& h2': theme.typography.h2,
            '& h3': theme.typography.h3,
            '& h4': theme.typography.h4,
            '& h5': theme.typography.h5,
            '& h6': theme.typography.h6,
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                marginBottom: theme.spacing(3),
            },
            '& p': {
                ...theme.typography.body1,
                marginBottom: theme.spacing(3),
            },
            '& div': {
                ...theme.typography.body1,
            },
            '& p:last-child': {
                marginBottom: 0,
            },
            '& ul': {
                ...theme.typography.body1,
                marginBottom: theme.spacing(3),
                listStylePosition: 'inside',
            },
            '& img': {
                maxWidth: '100%',
                height: 'auto',
            },
        }}>
            {ReactHtmlParser(html)}
        </Box>
    )
}

export default HTMLView;
