import React from 'react';
import { APP_VERSION } from 'src/config';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    Typography,
    Box,
    Container,
    IconButton,
    Grid,
    List,
    ListItem,
    Link,
    ListItemText,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import opiniao from 'src/theme/scss/images/opiniao.png';
import reclameaqui from 'src/theme/scss/images/reclameAquiFooter.png';
import exame from 'src/theme/scss/images/exame.png';
import visa from 'src/theme/scss/icons/visa.svg';
import master from 'src/theme/scss/icons/master.svg';
import diners from 'src/theme/scss/icons/diners.svg';
import hiper from 'src/theme/scss/icons/hiper.svg';
import hipercard from 'src/theme/scss/icons/hipercard.svg';
import amex from 'src/theme/scss/icons/amex.svg';
import elo from 'src/theme/scss/icons/elo.svg';
import boleto from 'src/theme/scss/icons/boleto.svg';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ManagerApi from 'src/services/managerApi';
import top from 'src/theme/scss/icons/top.svg';
import { addMessage, addLoadingGlobal, removeLoadingGlobal, openDialogGlobal, closeDialogGlobal } from 'src/actions';

import pagamentoBoletoIcon from 'src/theme/scss/icons/pagamento-boleto.svg';
import pagamentoCartaoIcon from 'src/theme/scss/icons/pagamento-cartao.svg';
import pagamentoNubankIcon from 'src/theme/scss/icons/pagamento-nubank.svg';
import pagamentoPagseguroIcon from 'src/theme/scss/icons/pagamento-pagseguro.svg';
import pagamentoPixIcon from 'src/theme/scss/icons/pagamento-pix.svg';
import pagamentoSantanderIcon from 'src/theme/scss/icons/pagamento-santander.svg';
import pagamentoAmeIcon from 'src/theme/scss/icons/pagamento-ame.svg';

import DialogTelefoneSuporte from '../../components/DialogTelefoneSuporte';
import DialogVendas from '../../components/DialogVendas';
import { EmutuaFooter } from 'src/components/EmutuaFooter';

import { PhoneInTalk as PhoneInTalkIcon } from '@material-ui/icons';
import { HeadsetMic as HeadsetMicIcon } from '@material-ui/icons';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        borderTop: '1px solid #e8e8e8',
        backgroundColor: '#f4f4f4'
    },
    title: {
        fontWeight: 800,
        textTransform: 'uppercase',
        marginBottom: 10
    },
    link: {
        paddingLeft: 0,
        paddingRight: 0,
        ...theme?.components?.footer?.link
    },
    linkIcon: {
        ...theme?.components?.footer?.linkIcon,
        margin: '0 5px'
    },
    text: {
        color: '#C9C9C9',
        textAlign: 'center',
        display: 'block'
    },
    newsletterRoot: {
        ...theme?.components?.footer?.newsletter?.root
    },

    socialIcon: {
        padding: theme.spacing(1),
    },
    logo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxHeight: 40
    },
    floatingBar: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        right: '25px',
        bottom: '25%',
        zIndex: 10,
        padding: '5px',
        borderRadius: '10px',
        [theme.breakpoints.down('xs')]: {
            right: theme.spacing(1),
        }
    },
    floatingBarIcon: {
        margin: '4px 0',
        padding: '5px',
    },
    floatingBarIconFacebook: {
        color: '#FFFFFF',
        backgroundColor: '#38529a',
        "&:hover": {
            backgroundColor: "#38529a"
        }
    },
    floatingBarIconInstagram: {
        color: '#FFFFFF',
        backgroundColor: '#e5518b',
        "&:hover": {
            backgroundColor: "#e5518b"
        }
    },
    floatingBarIconYoutube: {
        color: '#FFFFFF',
        backgroundColor: '#e63f36',
        "&:hover": {
            backgroundColor: "#e63f36"
        }
    },
    floatingBarIconWhatsapp: {
        color: '#FFFFFF',
        backgroundColor: '#76B900',
        height: '48px',
        width: '48px',
        transition: 'transform .3s',
        "&:hover": {
            color: '#FFFFFF',
            backgroundColor: "#76B900",
            transform: 'scale(1.125)'
        },
        "& svg": {
            height: '32px',
            width: '32px',
        }
    },
    buttonOk: {
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    list: {
        paddingBottom: 40
    },
    listItem: {
        fontFamily: 'Manrope',
        fontSize: 14,
        color: '#777777',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        position: 'relative',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    textItem: {
        margin: '0 0 3px 0'
    },
    paymentForms: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 220
    },
    paymentForms: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        justifyItems: 'center',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '270px',
            margin: '0 auto',
            padding: '25px 0'
        }
    },
    paymentForm: {
        '& img': {
            width: '30px',
            height: '30px',
            [theme.breakpoints.down('sm')]: {
                width: '20px',
                height: '20px',
            }
        },
    },
    securityItem: {
        marginBottom: 20
    },
    infoDefault: {
        borderTop: '2px solid #ececec'
    },
    logo: {
        maxWidth: 134
    },
    textInfo: {
        display: 'block',
        color: '#777777',
        marginBottom: 15
    },
    socialMedia: {
        textAlign: 'center'
    },
    newsletter: {
        padding: '70px 0',
        textAlign: 'center',
    },
    bigTitleNewsletter: {
        color: '#FFFFFF',
        textAlign: 'center',
        marginBottom: '-65px',
    },
    textNewsletter: {
        color: '#777777',
        marginBottom: '30px',
    },
    controlNewsletter: {
        width: '100%'
    },
    inputNewsletter: {
        color: '#777777',
        border: '1px solid #E4E4E4',
        backgroundColor: '#ffffff',
        width: '100%'
    },
    textItemBold: {
        color: '#333333'
    },
    floatingBarIconVendas: {
        color: '#FFFFFF',
        backgroundColor: '#db1842',
        height: '48px',
        width: '48px',
        transition: 'transform .3s',
        "&:hover": {
            color: '#FFFFFF',
            backgroundColor: "#db1842",
            transform: 'scale(1.125)'
        },
        "& svg": {
            height: '32px',
            width: '32px',
        }
    },
}));

function Footer({ className, ...rest }) {
    const dispatch = useDispatch();
    const dialogGlobal = useSelector((state) => state.interface.dialogGlobal);
    const classes = useStyles();
    const [translate, i18n] = useTranslation();
    const store = useSelector((state) => state.interface.store);

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const location = useLocation();
    let history = useHistory();

    let linkWhatsapp = false;
    if (store.contacts.whatsapp) {
        if (mobileSize) {
            linkWhatsapp = 'https://wa.me/' + store.contacts.whatsapp.match(/\d+/g).join([]);
        }
        else {
            linkWhatsapp = 'https://api.whatsapp.com/send?phone=' + store.contacts.whatsapp.match(/\d+/g).join([]);
        }
    }

    const newsletterValidationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(translate('errorRequiredField')),
        email: Yup.string().email(translate('errorEmailField')).max(255).required(translate('errorRequiredField')),
    });

    const [newsletterInterest, setNewsletterInterest] = React.useState('');
    const handleChange = (event) => {
        setNewsletterInterest(event.target.value);
    };


    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <>
            <Box pt={3} pb={3} className={clsx(classes.root, className)}>
                <Container maxWidth={false}>
                    <Grid container spacing={4}>
                        <Grid item md={4} xs={12} >
                            <Box display="flex" justifyContent={{ sm: 'flex-start', md: 'center' }}>
                                <List
                                    component="nav" className={classes.list}
                                    aria-labelledby="nested-list-subheader"
                                    subheader={
                                        <Typography component={Box} className={classes.title}>
                                            Vendas Corporativas
                                        </Typography>
                                    }
                                >
                                    <ListItem button component={RouterLink} to="/" className={classes.listItem}>
                                        <ListItemText className={classes.textItem} ><strong>Joinville/SC: </strong>(47) 3801-6000</ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Box display="flex" justifyContent={{ sm: 'flex-start', md: 'center' }}>
                                <List component="nav" className={classes.list}
                                    subheader={
                                        <Typography component={Box} className={classes.title}>
                                            Formas de Pagamento
                                        </Typography>
                                    }
                                >
                                    <RouterLink to="/formasdepagamento" className={classes.paymentForms}>
                                        <Box className={classes.paymentForm}>
                                            <img src={pagamentoBoletoIcon} />
                                        </Box>
                                        <Box className={classes.paymentForm}>
                                            <img src={pagamentoCartaoIcon} />
                                        </Box>
                                        <Box className={classes.paymentForm}>
                                            <img src={pagamentoSantanderIcon} />
                                        </Box>
                                        <Box className={classes.paymentForm}>
                                            <img src={pagamentoPagseguroIcon} style={{ transform: 'scale(2)', transformOrigin: 'left' }} />
                                        </Box>
                                        <Box className={classes.paymentForm}>
                                            <img src={pagamentoPixIcon} />
                                        </Box>
                                        <Box className={classes.paymentForm}>
                                            <img src={pagamentoNubankIcon} style={{ transform: 'scale(1.5)', transformOrigin: 'left' }} />
                                        </Box>
                                        <Box className={classes.paymentForm}>
                                            <img src={pagamentoAmeIcon} style={{ transform: 'scale(2.5)', transformOrigin: 'left' }} />
                                        </Box>
                                    </RouterLink>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Box display="flex" justifyContent={{ sm: 'flex-start', md: 'center' }}>
                                <List
                                    component="nav" className={classes.list}
                                    aria-labelledby="nested-list-subheader"
                                    subheader={
                                        <Typography component={Box} className={classes.title}>
                                            {store.name === 'Vinícola Vivalti' ? (
                                                <>
                                                    Reservas e Contatos
                                                </>
                                            ) : (
                                                <>
                                                    Horários de Atendimento
                                                </>
                                            )}
                                        </Typography>
                                    }
                                >
                                    <Box mt={1}>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText className={classes.textItem}><strong>Atendimento de Vendas</strong>:</ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText className={classes.textItem}>Segunda a sexta-feira das 09h às 18h30</ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText className={classes.textItem}>Sábado das 09h às 12h30</ListItemText>
                                        </ListItem>
                                    </Box>
                                    <Box mt={1}>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText className={classes.textItem}><strong>Atendimento do Suporte</strong>:</ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText className={classes.textItem}>Segunda a sexta-feira das 08h às 17h45</ListItemText>
                                        </ListItem>
                                    </Box>
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box className={classes.infoDefault} pt={2} pb={2}>
                <Grid container>
                    <Grid item md={12}>
                        <Box>
                            <Typography className={classes.text} variant="caption">
                                Preços e condições podem sofrer alterações sem prévia notificação. Em caso de preços divergentes, considere válido o preço do carrinho de compras.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {!mobileSize && (
                <Box className={classes.floatingBar}>
                    {/* {store.social.facebook && (
                    <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconFacebook)} component={Link} href={store.social.facebook} target="blank">
                        <FaceIcon />
                    </IconButton>
                )}

                {store.social.instagram && (
                    <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconInstagram)} component={Link} href={store.social.instagram} target="blank">
                        <InstaIcon />
                    </IconButton>
                )}

                {store.social.youtube && (
                    <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconYoutube)} component={Link} href={store.social.youtube} target="blank">
                        <YoutubeIcon />
                    </IconButton>
                )} */}

                    {/* <IconButton 
                    className={clsx(classes.floatingBarIcon, classes.floatingBarIconWhatsapp)} 
                    component={Link} 
                    onClick={() => { dispatch(openDialogGlobal('dialog-vendas')) }}
                >
                    <PhoneInTalkIcon  />
                </IconButton> */}


                    {/* {location.pathname == '/onestepcheckout/login' && (
                      <IconButton
                      className={clsx(classes.floatingBarIcon, classes.floatingBarIconVendas)}
                      component={Link}
                      onClick={() => { history.push('/central-de-vendas') }}
                  >
                      <HeadsetMicIcon />
                  </IconButton>
                )} */}

                    {linkWhatsapp && (
                        <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconWhatsapp)} component={Link} href={linkWhatsapp} target="blank">
                            <WhatsAppIcon />
                        </IconButton>
                    )}

                    <IconButton component={Link} onClick={scrollToTop}>
                        <img src={top} style={{ width: '40px', height: '40px' }} />
                    </IconButton>
                </Box>
            )}

            {store.maintenance && (
                <Snackbar
                    open={true}
                >
                    <Alert severity="warning">
                        Loja em manutenção. Nenhuma compra será aceita no momento.
                    </Alert>
                </Snackbar>
            )}

            {/* {dialogGlobal == 'dialog-vendas' ?
                <DialogVendas
                    open={true}
                    title=''
                    handleClose={() => { dispatch(closeDialogGlobal()) }}
                /> : null
            } */}

            {dialogGlobal == 'dialog-0800' ?
                <DialogTelefoneSuporte
                    open={true}
                    title=''
                    handleClose={() => { dispatch(closeDialogGlobal()) }}
                /> : null
            }

            <EmutuaFooter />

        </>
    );
}

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;
