import React, { useState } from 'react';
import { openDialogGlobal } from 'src/actions';
import { useSelector, useDispatch } from 'react-redux';

import chat from 'src/theme/scss/icons/chat.svg';
import sales from 'src/theme/scss/icons/sales.svg';
import support from 'src/theme/scss/icons/support.svg';
import call0800 from 'src/theme/scss/icons/call0800.svg';
import attendance from 'src/theme/scss/icons/attendance.svg';

import DialogTelefoneSuporte from 'src/components/DialogTelefoneSuporte';
import { HelpButton } from './HelpButton';

import { useHistory } from 'react-router-dom';

import {
    Box,
    Grid,
    Typography,
    Container,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import Swiper from 'react-id-swiper';

const useStyles = makeStyles((theme) => ({
    swiperMainContainer: {
        '& .swiper-container': {
            padding: '0 0 40px 0',
            '& .swiper-pagination-bullets': {
                bottom: 0,

                '& .swiper-pagination-bullet': {
                    backgroundColor: '#C9C9C9',
                },
                '& .swiper-pagination-bullet-active': {
                    backgroundColor: 'transparent',
                    border: '2px solid #e71b23',
                    height: '12px',
                    width: '12px',
                    transform: 'scale(1)'
                }
            }
        }
    },
    bigTitleHelp: {
        color: '#EBEBEB',
        textAlign: 'right',
        marginBottom: '-20px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
            marginBottom: '-10px',
        },
    },
    infoHelp: {
        position: 'relative',
        paddingLeft: 80
    },
    iconHelp: {
        position: 'absolute',
        left: '0',
        maxWidth: 60
    },
    subtitleHelp: {
        fontSize: '22px',
        fontWeight: '700',
        color: '#333333',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        },
    },
    itemsHelp: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '70px',
        [theme.breakpoints.down('1099')]: {
            padding: 0,
            overflowX: 'scroll',
            marginBottom: 30
        },
    },
    itemHelp: {
        border: '3px solid #ffffff',
        color: '#222222',
        backgroundColor: '#ffffff',
        maxWidth: '200px',
        minWidth: '180px',
        width: '100%',
        height: '180px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: '0 10px',
        flexDirection: 'column',
        '&:hover': {
            textDecoration: 'none',
            color: "#ed1c24",
            border: '3px solid #ed1c24',
            '& img': {
                filter: `invert(21%) sepia(78%) saturate(6302%) hue-rotate(349deg) brightness(95%) contrast(96%)`,
            }
        },
        '@media (max-width: 1700px)': {
            maxWidth: '140px',
            minWidth: '140px',
            width: '100%',
            height: '140px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 30px',
        },
    },
    hidden: {
        display: 'none'
    },
    show: {
        display: 'block'
    },
    redBullet: {
        width: '8px',
        height: '8px',
        display: 'block',
        borderRadius: '50%',
        backgroundColor: '#ED1C24'
    },
    chatOptionsTitle: {
        fontSize: 18,
        fontWeight: 700,
        color: '#333333'
    },
    chatOptionsContent: {
        padding: 32,
        backgroundColor: '#EFEFEF',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 16,
        '& button': {
            margin: 0
        }
    }
}));

export function Help() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));
    const history = useHistory();
    const store = useSelector((state) => state.interface.store);

    const [isAttendanceDialogOpen, setIsAttendanceDialogOpen] = useState(false);
    const [chatOptionsIsOpen, setChatOptionsIsOpen] = useState(false);

    const swiperParams = {
        speed: 500,
        loop: false,
        spaceBetween: 0,
        effect: 'slide',
        slidesPerView: 2,
        rebuildOnUpdate: true,
        observer: true,
        centeredSlides: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    };

    const handleOpenWhatsapp = (e) => {
        let linkWhatsapp = false;
        if (store.contacts.whatsapp) {
            if (mobileSize) {
                linkWhatsapp = 'https://wa.me/' + store.contacts.whatsapp.match(/\d+/g).join([]);
            }
            else {
                linkWhatsapp = 'https://api.whatsapp.com/send?phone=' + store.contacts.whatsapp.match(/\d+/g).join([]);
            }
        }
        return window.open(linkWhatsapp, '_blank')
    }

    function handleNavigateToSupport() {
        history.push('/suporte-tecnico');
    }

    const helpItems = [
        {
            id: 1,
            title: 'CHAT',
            icon: chat,
            onClick: () => handleOpenWhatsapp()
        },
        {
            id: 2,
            title: 'VENDAS',
            icon: sales,
            onClick: () => history.push('/central-de-vendas')
        },
        {
            id: 3,
            title: 'SUPORTE',
            icon: support,
            onClick: () => handleNavigateToSupport()
        },
        {
            id: 4,
            title: 'TELEFONE',
            icon: call0800,
            onClick: () => { dispatch(openDialogGlobal('dialog-0800')) }
        }
    ]

    return (
        <>
            <Box mt={mobileSize ? 0 : 10} mb={mobileSize ? 4 : 15}>
                <Container maxWidth={false}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        spacing={0}
                    >
                        <Grid item md={12}>
                            <Typography className={classes.bigTitleHelp} variant="overline">
                                precisa de ajuda?
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            spacing={0}
                        >
                            <Grid item md={4} sm={12} className={mobileSize ? classes.hidden : classes.show}>
                                <Box className={classes.infoHelp} display="flex" alignItems="center">
                                    <img src={attendance} className={classes.iconHelp} />
                                    <Box>
                                        <Typography className={classes.subtitleHelp}>
                                            Horários de Atendimento
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography variant="body1" className="text-1">
                                                <strong>Atendimento de Vendas:</strong>
                                            </Typography>
                                            <Typography variant="body1" className="text-1">
                                                Segunda a sexta-feira das 09h às 18h30
                                            </Typography>
                                            <Typography variant="body1" className="text-1">
                                                Sábado das 09h às 12h30
                                            </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant="body1" className="text-1">
                                                <strong>Atendimento do Suporte:</strong>
                                            </Typography>
                                            <Typography variant="body1" className="text-1">
                                                Segunda a sexta-feira das 08h às 17h45
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >
                                {mobileSize ? (
                                    <Grid container spacing={1}>
                                        {helpItems.map(helpItem => (
                                            <Grid item xs={3} key={helpItem.id}>
                                                <HelpButton
                                                    title={helpItem.title}
                                                    icon={helpItem.icon}
                                                    onClick={helpItem.onClick}
                                                    showBullet={false}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Box className={classes.itemsHelp}>
                                        {helpItems.map((helpItem, index) => (
                                            <HelpButton
                                                title={helpItem.title}
                                                icon={helpItem.icon}
                                                onClick={helpItem.onClick}
                                                showBullet={(index + 1) < helpItems.length}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </Grid>
                            <Grid item md={4} xs={12} className={mobileSize ? classes.show : classes.hidden}>
                                <Box mt={4} display="flex" alignItems="center">
                                    {/* <img src={attendance} className={classes.iconHelp} /> */}
                                    <Box>
                                        <Typography className={classes.subtitleHelp}>
                                            Horários de Atendimento
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography variant="body1" className="text-1">
                                                <strong>Atendimento de Vendas:</strong>
                                            </Typography>
                                            <Typography variant="body1" className="text-1">
                                                Segunda a sexta-feira das 09h às 18h30
                                            </Typography>
                                            <Typography variant="body1" className="text-1">
                                                Sábado das 09h às 12h30
                                            </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant="body1" className="text-1">
                                                <strong>Atendimento do Suporte:</strong>
                                            </Typography>
                                            <Typography variant="body1" className="text-1">
                                                Segunda a sexta-feira das 08h às 17h45
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
