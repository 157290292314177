import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    makeStyles,
    Tooltip,
    useMediaQuery,
    withStyles
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from 'src/actions';
import heartIcon from 'src/assets/pink-heart.png';
import personIcon from 'src/theme/scss/icons/person.svg';
import { LoginModal } from '../../Login/Modal';
import OrderStatus from './OrderStatus';

import DialogWhatsApp from 'src/views/Content/Suporte/DialogWhatsApp';

const useStyles = makeStyles((theme) => ({
    boxStatusGroup: {
        position: 'relative'
    },
    boxOrderStatus: {
        position: 'absolute',
        left: 0,
        zIndex: 9
    },
    boxPopUpLogin: {
        position: 'absolute',
        zIndex: 9
    },
    // button: {
    //     paddingTop: 0,
    //     paddingBottom: 0,
    //     paddingLeft: theme.spacing(3),
    //     paddingRight: theme.spacing(3),
    // },
    orderStatus: {
        '& svg': {
            marginLeft: '10px'
        },
        '&:after': {
            display: 'none !important'
        }
    },
    logoutIconBox: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    logoutIcon: {
        marginLeft: 20,
        height: 21
    },
    userIcon: {
        marginRight: '5px',
        height: '21px'
    },
    iconWhite: {
        filter: `invert(100%) sepia(4%) saturate(8%) hue-rotate(96deg) brightness(104%) contrast(100%)`
    },
    iconRed: {
        // filter: `invert(17%) sepia(65%) saturate(5122%) hue-rotate(349deg) brightness(97%) contrast(94%)`
        filter: `invert(46%) sepia(72%) saturate(5661%) hue-rotate(320deg) brightness(104%) contrast(102%)`
    },
    userIconBox: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    userIconBoxRed: {
        color: '#ff367d', //'#ED1C24'
    },
    useIconBoxWhite: {
        color: '#fff'
    },
    loginBlock: {
        position: 'absolute',
        left: 0,
        zIndex: 9,
        '@media (max-width: 960px)': {
            left: 'initial',
            right: 0
        }
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: 0,
        margin: 4,
    },
}))(Tooltip);

function TopMenu() {
    const [orderBlock, setOrderBlock] = useState(false);
    const classes = useStyles();

    const [translate, i18n] = useTranslation();

    const isHomePage = window.location.pathname === '/';

    const dispatch = useDispatch();

    const store = useSelector((state) => state.interface.store);
    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    let history = useHistory();

    const [anchorMenu, setAnchorMenu] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [isModalOpened, setIsModalOpened] = useState(false);

    const openDrop = Boolean(anchorEl);

    const [showLoginBlock, setShowLoginBlock] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDrop = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (event) => {
        if (anchorMenu !== event.currentTarget) {
            setAnchorMenu(event.currentTarget);
        }
    };

    const getFirstName = () => {
        return userAccount.name.split(" ")[0]
    }

    const userAccount = useSelector((state) => state.account.user);

    const logoutUser = async () => {
        dispatch(logout());
        history.push('/');
    }

    const [openWhats, setOpenWhats] = React.useState(false);

    const handleClickOpenWhats = () => {
        setOpenWhats(true);
    };

    const handleCloseWhats = () => {
        setOpenWhats(false);
    };

    return (
        <>
            <Box className={`top-header ${!isHomePage ? 'top-header--gray' : ''}`}>
                <Container maxWidth={false}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Box display="flex">
                                <Button component={RouterLink} to='/wishlist' className='btnTopHeader'>
                                    <img src={heartIcon} alt="Lista de Desejos" className="icon" />
                                    Lista de Desejos
                                </Button>
                                {userAccount == null && (
                                    <Box className={classes.boxStatusGroup}>
                                        <Button
                                            id="status-button"
                                            variant="contained"
                                            disableElevation
                                            onClick={() => setOrderBlock(!orderBlock)}
                                            className={`btnTopHeader ${classes.orderStatus}`}
                                        >
                                            Status de Pedidos
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                {!openDrop
                                                    ? <path d="M9.6775 5.01074L7 7.68241L4.3225 5.01074L3.5 5.83324L7 9.33324L10.5 5.83324L9.6775 5.01074Z" fill="#777777" />
                                                    : <path d="M7 4.66666L3.5 8.16666L4.3225 8.98916L7 6.31749L9.6775 8.98916L10.5 8.16666L7 4.66666Z" fill={isHomePage ? '#ffffff' : '#333333'} />
                                                }
                                            </svg>
                                        </Button>
                                        {orderBlock && (
                                            <Box className={classes.boxOrderStatus}>
                                                <OrderStatus />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        <Grid item>
                            {/* Autenticado ou não */}
                            {userAccount != null ?
                                <>
                                    <Button
                                        component={RouterLink}
                                        to='/customer/account'
                                        className='btnTopHeader'>
                                        <Box className={`${classes.userIconBox} ${isHomePage ? classes.useIconBoxWhite : classes.userIconBoxRed}`}>
                                            <img className={`${classes.userIcon} ${isHomePage ? classes.iconWhite : classes.iconRed}`} src={personIcon} />
                                            Olá, {getFirstName()}
                                        </Box>
                                    </Button>

                                    {/* <LightTooltip
                                        interactive
                                        title={
                                            <>
                                                <MenuItem component={RouterLink} to='/customer/account'> Meus dados</MenuItem>
                                                <MenuItem component={RouterLink} to='/customer/account/addresses'>Meus endereços</MenuItem>
                                                <MenuItem component={RouterLink} to='/customer/account/update-password'> Alterar minha senha</MenuItem>
                                            </>
                                        }
                                    >
                                        <Button
                                            aria-controls="fade-menu"
                                            aria-haspopup="true"
                                            onMouseOver={handleClickMenu}
                                            component={RouterLink}
                                            to='/customer/account'
                                            className='btnTopHeader'>
                                            Minha conta
                                        </Button>
                                    </LightTooltip> */}

                                    <Button
                                        aria-controls="fade-menu"
                                        aria-haspopup="true"
                                        onMouseOver={handleClickMenu}
                                        component={RouterLink}
                                        to='/customer/account'
                                        className='btnTopHeader'>
                                        Minha conta
                                    </Button>

                                    <Button component={RouterLink} to='/customer/account/orders' className='btnTopHeader'>
                                        Meus pedidos
                                    </Button>
                                    <Button onClick={logoutUser} component={RouterLink} to='#' className='btnTopHeader'>
                                        <Box className={classes.logoutIconBox}>
                                            Sair
                                            {/* <img className={`${classes.logoutIcon} ${isHomePage ? classes.iconWhite : ''}`} src={arrowLogout} /> */}
                                        </Box>
                                    </Button>

                                </> :
                                <>
                                    <Button component={RouterLink} to='/onestepcheckout/customer/register' className='btnTopHeader'>
                                        Cadastre-se
                                    </Button>
                                    <Box style={{ display: 'inline-block', position: 'relative' }}>
                                        <Button onClick={() => setShowLoginBlock(!showLoginBlock)} className='btnTopHeader'>
                                            Entrar
                                        </Button>
                                        {showLoginBlock &&
                                            <Box className={classes.loginBlock}>
                                                <LoginModal close={() => setShowLoginBlock(false)} />
                                            </Box>
                                        }
                                    </Box>
                                </>
                            }
                        </Grid>
                        <Grid item>
                            <Button className='btnTopHeader' onClick={handleClickOpenWhats}>
                                Peças Avulsas
                            </Button>
                            <Button component={RouterLink} to="/central-de-vendas" className='btnTopHeader'>
                                Central de Vendas
                            </Button>
                            {/* <Button component={RouterLink} to="/seja-revendedor" className='btnTopHeader'>
                                Seja Revendedor
                            </Button> */}
                            <Button component={RouterLink} to='/suporte-tecnico' className='btnTopHeader'>
                                Suporte Técnico
                            </Button>
                            <Button component={Link} href='https://store.nvidia.com/pt-br/consumer/?page=1&limit=12&locale=pt-br&manufacturer=AVELL&manufacturer_filter=ACER~1,AOC~1,ASUS~18,AVELL~10,DELL~1,GALAX~8,GIGABYTE~24,LENOVO~2,MSI~13,PALIT~6,PNY~11,SAMSUNG~1,ZOTAC~9' target="_blank" className='btnTopHeader'>
                                NVIDIA Store
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            {openWhats ?
                <DialogWhatsApp
                    open={openWhats}
                    title=''
                    handleClose={() => handleCloseWhats()}
                /> : null}

        </>
    );
}

TopMenu.propTypes = {
    className: PropTypes.string
};

export default TopMenu;
