import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { getProductValues } from 'src/utils/functions';
import AddToCartButton from './AddToCartButton';
import FavoriteButton from '../common/FavoriteButton';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'block',
        textDecoration: 'none',
        height: '100%',
    },
    paper: {
        color: '#232327',
        padding: '8px',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            padding: '16px',
        },
    },
    productImage: {
        display: 'block',
        width: '100%',
        height: 'auto',
        marginBottom: '8px',
        maxHeight: '110px',
        objectFit: 'contain'
    },
    productName: {
        fontSize: '14px',
        fontWeight: '700px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
        },
    },
    attributes: {
        marginTop: '4px',
        marginBottom: '8px',
        fontSize: '10px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
    },
    details: {
        marginTop: 'auto',
    },
    pricing: {
        marginBlock: '8px',
    },
    pricePrefix: {
        color: 'inherit',
        fontSize: '8px',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: '12px',
        },
    },
    priceValue: {
        fontFamily: 'Gilroy-ExtraBold',
        color: 'inherit',
        display: 'inline',
        fontSize: '14px',
        fontWeight: '700',
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
    },
    priceSufix: {
        color: 'inherit',
        fontSize: '10px',
        display: 'inline',
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
    },
    installments: {
        color: 'inherit',
        fontSize: '10px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        '& span': {
            color: '#0085f8',
            fontWeight: 'bold',
            fontSize: '10px',
            [theme.breakpoints.up('sm')]: {
                fontSize: '14px',
            },
        },
    },
    favoriteButton: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '2px',
        [theme.breakpoints.up('sm')]: {
            padding: '8px',
        },
        '& svg': {
            color: '#0077df',
            width: '16px',
            height: '16px',
            [theme.breakpoints.up('sm')]: {
                width: '24px',
                height: '24px',
            },
            '&.filled': {
                color: '#FF367D',
            },
        },
    },
}));

function MobileProductCard({ product }) {
    const classes = useStyles();

    const imageSrc = product.medias[0]?.url;

    const discountPercentage = 10;
    const { formattedDiscountPrice, installmentValueWithInterest } = getProductValues(product, discountPercentage);

    const attributes = useMemo(() => {
        if (!product.attributes) {
            return;
        }
        const attributes = product.attributes
            .filter(attribute => attribute.isList)
            .map(attribute => ({ ...attribute, type: attribute.type }))
            .sort((a, b) => a.sortOrder - b.sortOrder);
        return attributes
            .filter(attribute => attribute.type === 'text' ? attribute.optionId : attribute.optionLabel)
            .map(attribute => (
                <>
                    {attribute.type === 'text' ? attribute.optionId : attribute.optionLabel}
                    <br />
                </>
            ))
    }, [product]);

    function handleFavorite(event) {
        event.preventDefault();
    }

    return (
        <Link to={`/${product.url}`} className={classes.link}>
            <Paper elevation={0} className={classes.paper}>
                <img className={classes.productImage} src={imageSrc} alt={product.name} />
                <Typography variant="h3" className={classes.productName}>
                    {product.name}
                </Typography>
                {attributes && <Typography className={classes.attributes}>{attributes}</Typography>}
                <Box className={classes.details}>
                    <Box className={classes.pricing}>
                        <Typography className={classes.pricePrefix}>A partir de</Typography>
                        <Box>
                            <Typography className={classes.priceValue}>{formattedDiscountPrice}</Typography>{' '}
                            <Typography className={classes.priceSufix}>no PIX</Typography>
                        </Box>
                        <Typography className={classes.installments}>
                            ou <span>21x de {installmentValueWithInterest}</span>
                        </Typography>
                    </Box>
                    <AddToCartButton product={product} />
                </Box>
                <Box className={classes.favoriteButton}>
                    <FavoriteButton product={product} />
                </Box>
            </Paper>
        </Link>
    );
}

export default MobileProductCard;
